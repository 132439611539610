
.divStart {
    margin: auto;
    padding-top: 40px;
    width: 300px;
}

.divStart a {
    color: #9ad184;
}

.header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.header img {
    width: 100%;
}

.loginButton {
    width: 310px;
    color: #444753;
    font-size: 20px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    background: #9ad184;
    padding: 10px;
    border-radius: 5px;
}

.codeInput {
    width: 300px;
    border: none;
    padding: 10px 0px 10px 10px;
    font: 22px/26px "Lato", Arial, sans-serif;
    margin-bottom: 10px;
    border-radius: 5px;
    resize: none;
}